<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Voucher # <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.voucherNumber"
                  :rules="[v => !!v || 'Voucher is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Pay Date</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.payDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.payDate" @input="menuDate = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Kasbon External <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  :items="kasbonExternals"
                  item-text="id"
                  background-color="white"
                  v-model="kasbonExternal"
                  @change="onChangeKasbonExternal"
                  :rules="[v => !!v || 'Kasbon External is required']"
                  return-object
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Employee <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.employeeName"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Division</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.division"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Method</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.paymentMethod"
                  :items="listPaymentMethod"
                  :rules="[v => !!v || 'Method is required']"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="form.paymentMethod === 'Transfer'">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Account Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  flat
                  dense
                  outlined
                  background-color="white"
                  :items="listAccountNumber"
                  item-value="id"
                  item-text="accountNumber"
                  v-model="form.accountNumberId"
                  :rules="[v => !!v || 'Account Number is required']"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Currency</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.currency"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Account</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="bank"
                  :items="listBank"
                  item-text="accountName"
                  :filter="filterObject"
                >
                  <template v-slot:item="data">
                    <v-list-item v-on="data.on" @click.stop="addBank(data.item)">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.accountNumber"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank Charge</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.bankCharge"
                  :rules="[v => v >= 0 || 'Bank Charge must more than 0']"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- <v-row v-if="form.banks.length > 0" class="my-2">
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12" class="py-0">
                <account-list :items="form.banks" @deleteItem="deleteItemBank"></account-list>
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Other Account</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  :items="accounts"
                  item-text="accountName"
                  return-object
                  v-model="account"
                  :filter="filterObject"
                >
                  <template v-slot:item="data">
                    <v-list-item v-on="data.on" @click.stop="addAccount(data.item)">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.accountNumber"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="form.remark"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- <v-row v-if="form.accounts.length > 0" class="my-2">
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12" class="py-0">
                <account-table
                  :items="form.accounts"
                  @deleteItem="deleteItemAccount"
                ></account-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"> </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">Kasbon <span class="red--text">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="items"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:item.no="{ item }">
                          {{ items.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.amount="{ item }">
                          {{ formatPrice(item.amount) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"> </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">
                        Bank / Cash <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headerBank"
                        :items="form.banks"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRowBank"
                      >
                        <template v-slot:item.no="{ index }">
                          {{ index + 1 }}
                        </template>
                        <template v-slot:item.amount="{ item }">
                          {{ formatPrice(item.amount) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"> </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">Accounts</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headerAccount"
                        :items="form.accounts"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRowAccount"
                      >
                        <template v-slot:item.no="{ index }">
                          {{ index + 1 }}
                        </template>
                        <template v-slot:item.amount="{ item }">
                          {{ formatPrice(item.amount) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Kasbon</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-btn color="primary" @click="checkJournal">
              <v-icon large>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-review-journal
      :dialog="dialogReview"
      :journals="journals"
      @save="submit"
      @close="close"
      :currency="form.currency"
    ></dialog-review-journal>
    <dialog-bank
      :dialog="dialogBank"
      @save="saveBank"
      @close="close"
      :item="item"
      @deleteBank="deleteBank"
      :index="index"
      :type="type"
    ></dialog-bank>
  </v-form>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
// import AccountList from "@/components/AccountList";
// import AccountTable from "@/components/AccountTable";
import DialogReviewJournal from "@/components/DialogReviewJournal";
import DialogBank from "@/components/DialogBank";

export default {
  name: "create-kasbon-external",
  components: {
    // "account-list": AccountList,
    // "account-table": AccountTable,
    DialogReviewJournal,
    DialogBank,
  },
  data: () => ({
    defaultForm: {
      formNumber: "",
      payDate: moment().format("yyyy-MM-DD"),
      voucherNumber: "",
      paymentMethod: "",
      division: "",
      currency: "",
      site: "",
      employeeId: null,
      employeeName: "",
      kasbonId: null,
      kasbonName: "",
      remark: "",
      accountNumberId: null,
      totalAmount: 0,
      bankCharge: 0,
      items: [],
      banks: [],
      accounts: [],
      status: "Outstanding",
    },
    menuDate: false,
    listBank: [],
    accounts: [],
    form: {},
    items: [],
    account: null,
    bank: null,
    kasbonExternal: null,
    employee: null,
    listPaymentMethod: ["Giro", "Cash", "Transfer"],
    dialogReview: false,
    dialogBank: false,
    item: {},
    index: -1,
    type: "",
    journals: [],
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Description",
        value: "description",
        sortable: false,
        divider: true,
      },
      {
        text: "Category",
        value: "category",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
    ],
    headerBank: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Number",
        value: "accountNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
    ],
    headerAccount: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Number",
        value: "accountNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
      },
      {
        text: "Nature",
        value: "type",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
    ],
    valid: true,
    edit: false,
  }),

  computed: {
    ...mapState("kasbonExternal", ["lastFormNumber", "kasbonExternals", "lastVoucherNumber"]),
    ...mapState("generalKasbon", ["listAccountNumber"]),
    ...mapState("currency", ["dropDownCurrency"]),
    ...mapState("external", ["employees"]),
  },

  watch: {},

  methods: {
    checkJournal() {
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.journals = [
          {
            accountName: `KASBON ${this.form.currency}`,
            debit: this.form.totalAmount,
            credit: 0,
          },
        ];
        if (this.form.bankCharge > 0) {
          this.journals.unshift({
            accountName: `BIAYA ADMINISTRASI BANK`,
            debit: this.form.bankCharge,
            credit: 0,
          });
        }

        this.form.banks.forEach(x => {
          this.journals.push({
            accountName: x.accountName,
            debit: 0,
            credit: x.amount,
          });
        });
        this.form.accounts.forEach(element => {
          if (element.type === "Debit") {
            this.journals.unshift({
              accountName: `${element.accountName}`,
              debit: element.amount,
              credit: 0,
            });
          } else {
            this.journals.push({
              accountName: `${element.accountName}`,
              debit: 0,
              credit: element.amount,
            });
          }
        });
        this.dialogReview = true;
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    submit() {
      if (this.$refs.form.validate() && this.form.banks.length > 0) {
        this.form.formNumber = this.lastFormNumber;
        this.form.items = this.items;

        let totalDebit = this.form.totalAmount + this.form.bankCharge;
        let totalCredit = 0;
        this.form.banks.forEach(element => {
          totalCredit += element.amount;
        });
        this.form.accounts.forEach(element => {
          if (element.type === "Debit") {
            totalDebit += element.amount;
          } else {
            totalCredit += element.amount;
          }
        });

        if (totalDebit - totalCredit === 0) {
          this.$store.commit("SET_LOADER", true);
          this.$store
            .dispatch("kasbonExternal/create", this.form)
            .then(() => {
              this.$store.commit("SET_LOADER", false);
            })
            .catch(() => {
              this.$store.dispatch("toast", {
                type: "error",
                message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
              });
              this.$store.commit("SET_LOADER", false);
            });
        } else {
          let different = totalDebit - totalCredit;
          if (different > 0) {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Debit`,
            });
          } else {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Credit`,
            });
          }
        }
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Account is required",
        });
      }
    },
    onChangeKasbonExternal(val) {
      this.items = [];
      this.form.kasbonId = val.id;
      this.form.kasbonName = val.name;
      this.form.currency = val.currency;
      this.form.totalAmount = val.totalAmount;
      this.form.employeeId = val.user.id;
      this.form.employeeName = val.user.name;
      this.form.division = val.user.department.name;
      this.form.site = val.site;
      val.items.forEach(item => this.setItem(item));
      this.$store.dispatch("generalKasbon/getListAccountNumber", val.user.id);
    },
    async getKasbonExternals() {
      await this.$store.dispatch("kasbonExternal/getKasbonExternals", 0);
    },
    setItem(item) {
      let body = {
        itemId: item.id,
        description: item.description,
        category: item.category ? item.category.name : "",
        accountNumber: item.accountNumber,
        accountName: item.accountName,
        accountId: item.category ? item.category.extChartOfAccountCategoryId : 0,
        amount: item.amount,
      };

      this.items.push(body);
    },
    deleteBank() {
      if (this.type === "bank") {
        if (this.index > -1) {
          this.form.banks.splice(this.index, 1);
        }
      } else {
        if (this.index > -1) {
          this.form.accounts.splice(this.index, 1);
        }
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    addBank(item) {
      this.item = item;
      this.type = "bank";
      this.dialogBank = true;
    },
    addAccount(item) {
      this.item = item;
      this.type = "other";
      this.dialogBank = true;
    },
    async getBanks() {
      await this.$store.dispatch("chartOfAccount/getBanks").then(response => {
        this.listBank = response.data;
      });
    },
    // eslint-disable-next-line no-unused-vars
    filterObject(item, queryText, itemText) {
      return (
        item.accountName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.accountNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    async getAccounts() {
      const response = await this.$store.dispatch("chartOfAccount/getListAccountGL");
      if (response.status === 200) {
        this.accounts = response.data;
      }
    },
    close() {
      this.dialogReview = false;
      this.dialogBank = false;
      this.edit = false;
    },
    async getIntialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        await this.$store.dispatch("kasbonExternal/getLastFormNumber"),
        await this.$store.dispatch("kasbonExternal/getLastVoucherNumber"),
        await this.$store.dispatch("currency/getListCurrency"),
        this.getKasbonExternals(),
        this.getBanks(),
        this.getAccounts(),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    saveBank() {
      if (this.type === "bank") {
        if (this.edit) {
          this.form.banks[this.index] = this.item;
          this.edit = false;
        } else {
          if (this.form.banks.indexOf(this.item) > -1) {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Account Sudah Digunakan",
            });
          } else {
            this.form.banks.push(this.item);
          }
        }
      } else {
        if (this.edit) {
          this.form.accounts[this.index] = this.item;
          this.edit = false;
        } else {
          if (this.form.accounts.indexOf(this.item) > -1) {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Account Sudah Digunakan",
            });
          } else {
            this.form.accounts.push(this.item);
          }
        }
      }
    },
    onClickRowBank(item) {
      this.item = item;
      this.index = this.form.banks.indexOf(item);
      this.type = "bank";
      this.edit = true;
      this.dialogBank = true;
    },
    onClickRowAccount(item) {
      this.item = item;
      this.index = this.form.accounts.indexOf(item);
      this.type = "bank";
      this.edit = true;
      this.dialogBank = true;
    },
  },

  created() {
    this.getIntialData();
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
